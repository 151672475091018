import React, { createContext, useContext, useEffect, useState } from "react";
import { AnimatePresence, motion, Reorder } from "framer-motion";
import { sleep } from "lib/tangle-js/functions";
import { Howl } from "howler";
import { SpectodaSound } from "lib/tangle-js/SpectodaSound";
import { tangleDevice } from "lib/utils/communication";
import { nanoevents } from "./LeaverBuster";

const spectodaSound = new SpectodaSound();
export let kateLocked = false;
let lastValue = 1;

spectodaSound.on("loudness", (value: number) => {
  // process.env.NODE_ENV !== "development" && tangleDevice.setDebugLevel(0);
  if (lastValue !== 0) {
    tangleDevice.emitPercentageEvent("sound", value);
  }

  lastValue = value;
  console.log(lastValue);

  // console.log(value);
});

export default function Kate() {
  const { messages, activated } = useContext(KateContext);
  // const [text, setText] = React.useState("");

  // useEffect(() => {
  //   "speechSynthesis" in window
  //     ? console.log("Web Speech API supported!")
  //     : console.log("Web Speech API not supported :-(");

  //   const synth = window.speechSynthesis;
  //   let ourText = text;
  //   const utterThis = new SpeechSynthesisUtterance(ourText);

  //   synth.speak(utterThis);
  //   synth.addEventListener("end", () => {});
  // }, [text]);

  useEffect(() => {
    setTimeout(() => {
      // setTimeout(() => {
      //   setText("Ahoj ja jsem KATE.");
      // }, 0);
      // setTimeout(() => {
      //   setText("Jsem největší borka pod sluncem.");
      // }, 3000);
      // setTimeout(() => {
      //   setText("Copak si zahrajem frajere?");
      // }, 7000);
      // setTimeout(() => {
      //   setText("Pexeso? 😉😉");
      // }, 8500);
    }, 5000);
  }, []);

  // useEffect(() => {
  //   const ref = spectodaSound.on("loudness", (value: number) => {
  //     // process.env.NODE_ENV !== "development" && tangleDevice.setDebugLevel(0);
  //     tangleDevice.emitPercentageEvent("sound", value);
  //     // console.log(value);
  //   });
  //   return () => ref();
  // }, [spectodaSound]);

  return (
    <AnimatePresence>
      {activated && (
        <motion.div animate={{ opacity: 1, y: 0 }} initial={{ opacity: 0, y: 42 }} exit={{ opacity: 0, y: 42 }} className="fixed bottom-10 left-20 flex items-end">
          <div className="scale-75">
            <KateImage talking={false} />
          </div>

          <div className="flex flex-col">
            <AnimatePresence>
              {messages.map(msg => (
                <motion.div
                  exit={{ opacity: 0, y: -30, transition: { duration: 0.5 } }}
                  animate={{ opacity: 1, y: 0 }}
                  initial={{ opacity: 0, y: 20 }}
                  key={msg.id}
                  className="ml-2 bubble text-[#003366] bg-white p-3  px-5 rounded-xl mb-8 max-w-[260px] transition-all font-semibold"
                >
                  <p>{msg.msg}</p>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

function KateImage({ talking }: { talking: boolean }) {
  return (
    <div className="">
      <motion.svg width="87" height="87" viewBox="0 0 87 87" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M87 43.5C87 67.5244 67.5244 87 43.5 87C19.4756 87 0 67.5244 0 43.5C0 19.4756 19.4756 0 43.5 0C67.5244 0 87 19.4756 87 43.5Z" fill="#B2E7FA" />
        <path
          d="M83.4173 43.4997C83.4173 65.5456 65.5456 83.4173 43.4997 83.4173C21.4538 83.4173 3.58203 65.5456 3.58203 43.4997C3.58203 21.4538 21.4538 3.58203 43.4997 3.58203C65.5456 3.58203 83.4173 21.4538 83.4173 43.4997Z"
          fill="#E6F7FD"
        />
        <path
          d="M79.3228 43.5003C79.3228 63.2851 63.2841 79.3238 43.4993 79.3238C23.7145 79.3238 7.67578 63.2851 7.67578 43.5003C7.67578 23.7155 23.7145 7.67676 43.4993 7.67676C63.2841 7.67676 79.3228 23.7155 79.3228 43.5003Z"
          fill="#F5FCFF"
        />
        <path
          d="M75.2284 43.4999C75.2284 61.0236 61.0226 75.2293 43.4989 75.2293C25.9753 75.2293 11.7695 61.0236 11.7695 43.4999C11.7695 25.9762 25.9753 11.7705 43.4989 11.7705C61.0226 11.7705 75.2284 25.9762 75.2284 43.4999Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.0998 37.3584C27.089 37.3584 27.8909 38.1603 27.8909 39.1496V47.8496C27.8909 48.8388 27.089 49.6408 26.0998 49.6408C25.1105 49.6408 24.3086 48.8388 24.3086 47.8496V39.1496C24.3086 38.1603 25.1105 37.3584 26.0998 37.3584Z"
          fill="#00AEEF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.799 20.7266C35.7882 20.7266 36.5902 21.5285 36.5902 22.5177L36.5902 64.4824C36.5902 65.4717 35.7882 66.2736 34.799 66.2736C33.8098 66.2736 33.0078 65.4717 33.0078 64.4824L33.0078 22.5177C33.0078 21.5285 33.8097 20.7266 34.799 20.7266Z"
          fill="#00AEEF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.5002 33.5205C44.4894 33.5205 45.2913 34.3224 45.2913 35.3117L45.2913 51.6882C45.2913 52.6774 44.4894 53.4793 43.5002 53.4793C42.5109 53.4793 41.709 52.6774 41.709 51.6882L41.709 35.3117C41.709 34.3224 42.5109 33.5205 43.5002 33.5205Z"
          fill="#00AEEF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.1994 28.915C53.1886 28.915 53.9906 29.717 53.9906 30.7062L53.9906 56.2945C53.9906 57.2837 53.1886 58.0856 52.1994 58.0856C51.2101 58.0856 50.4082 57.2837 50.4082 56.2945L50.4082 30.7062C50.4082 29.717 51.2101 28.915 52.1994 28.915Z"
          fill="#00AEEF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60.9006 37.3584C61.8898 37.3584 62.6917 38.1603 62.6917 39.1496V47.8496C62.6917 48.8388 61.8898 49.6408 60.9006 49.6408C59.9113 49.6408 59.1094 48.8388 59.1094 47.8496V39.1496C59.1094 38.1603 59.9113 37.3584 60.9006 37.3584Z"
          fill="#00AEEF"
        />
      </motion.svg>
      <div
        style={{
          height: 12,
          width: 100,
          background: "#003366",
          opacity: 0.8,
          filter: "blur(28px)",
        }}
      ></div>
    </div>
  );
}

interface KateMsg {
  msg: string;
  mp3: string;
  id?: number;
  delay?: number;
}

interface KateProviderProps {
  say(msg: KateMsg): Promise<void>;
  sayRandom(msg: KateMsg[]): Promise<void>;
  queue(msg: KateMsg): Promise<void>;
  stop(): void;
  messages: KateMsg[];
  activate: (activate: boolean) => void;
  activated: boolean | "yes";
}

export const KateContext = createContext<KateProviderProps>({
  say: msg => {
    return new Promise(() => {});
  },
  sayRandom: msgs => new Promise(() => {}),
  queue: msg => {
    return new Promise(() => {});
  },
  stop: () => {},
  messages: [],
  activate: () => {},
  activated: false,
});

let players: any = [];

class KateSpeaker {
  // constructor() {
  //   "speechSynthesis" in window
  // ? console.log("Web Speech API supported!")
  // : console.log("Web Speech API not supported :-(");
  // const synth = window.speechSynthesis;
  // let ourText = text;
  // const utterThis = new SpeechSynthesisUtterance(ourText);
  // synth.speak(utterThis);
  // synth.addEventListener("end", () => {
  // })
  // }
  static async sayRandom(msgs: KateMsg[]) {
    return this.say(msgs[Math.floor(Math.random() * msgs.length)]);
  }

  static async say(msg: KateMsg) {
    // console.log("Rikam", msg);
    kateLocked = true;

    if (msg.mp3) {
      return new Promise(async (resolve, reject) => {
        await sleep(msg.delay || 0);
        const player = new Howl({
          src: [msg.mp3],
          autoplay: true,
          loop: false,
          html5: true,
          volume: 1,
          onplay: async function () {
            const audio =
              // @ts-ignore
              player?._sounds && player._sounds[0] && player._sounds[0]._node;
            if (audio) {
              spectodaSound.stop();
              await spectodaSound.connect(audio.captureStream());
              spectodaSound.start();
            } else {
              setTimeout(async () => {
                const audio =
                  // @ts-ignore
                  player?._sounds && player._sounds[0] && player._sounds[0]._node;
                spectodaSound.stop();
                await spectodaSound.connect(audio.captureStream());
                spectodaSound.start();
              }, 100);
            }
          },
          onend: function () {
            // console.log("Finished!");
            kateLocked = false;
            resolve(null);
            spectodaSound.stop();
          },
          onload: async function () {
            players.push(player);

            while (players.length > 1) {
              let player = players[0];
              player.stop();

              players.splice(0, 1);
            }
            spectodaSound.stop();
          },
          onloaderror: function () {
            kateLocked = false;

            resolve(null);
          },
          onplayerror: function () {
            kateLocked = false;

            resolve(null);
          },
        });
      });
    } else {
      await sleep(msg.delay || 0);
      await sleep(1500);
      kateLocked = false;
      return new Promise(resolve => resolve(null));

      // const synth = window.speechSynthesis;
      // const utterThis = new SpeechSynthesisUtterance(msg.msg);
      // // const voices = synth.getVoices();
      // // console.log(voices);

      // synth.speak(utterThis);
      // tangleDevice.emitPercentageEvent("sound", 100);

      // return new Promise((resolve, reject) => {
      //   utterThis.addEventListener("end", () => {
      //     resolve(null);
      //     tangleDevice.emitPercentageEvent("sound", 0);
      //   });
      // });
    }
  }
}

export function KateProvider({ children }: React.PropsWithChildren<{}>) {
  const [messages, setMessages] = useState<KateMsg[]>([]);
  const [activated, setActivated] = useState<boolean | "yes">(false);

  useEffect(() => {
    // "speechSynthesis" in window
    //   ? console.log("Web Speech API supported!")
    //   : console.log("Web Speech API not supported :-(");
    // const synth = window.speechSynthesis;
    // // let ourText = text;
    // const utterThis = new SpeechSynthesisUtterance(ourText);
    // synth.speak(utterThis);
    // synth.addEventListener("end", () => {
    // })
  }, []);

  async function say(msg: KateMsg) {
    const id = Math.random();
    setMessages([{ ...msg, id }]);
    let lastActivated = activated;
    setActivated("yes");
    await sleep(200);
    await KateSpeaker.say(msg);
    setMessages(msgs => msgs.filter(v => v.id !== id));
    setActivated(activated => {
      if (activated === "yes" && !lastActivated) {
        // spectodaSound.stop();
        return false;
      } else {
        if (activated === "yes" && lastActivated) {
          return true;
        } else if (activated === true) {
          return true;
        } else {
          // spectodaSound.stop();
          return false;
        }
      }
    });
  }

  async function sayRandom(msgs: KateMsg[]) {
    const id = Math.random();
    const msg = msgs[Math.floor(Math.random() * msgs.length)];
    setMessages([{ ...msg, id }]);
    let lastActivated = activated;
    setActivated("yes");
    await sleep(200);
    await KateSpeaker.say(msg);
    setMessages(msgs => msgs.filter(v => v.id !== id));
    setActivated(activated => {
      if (activated === "yes" && !lastActivated) {
        // spectodaSound.stop();
        return false;
      } else {
        if (activated === "yes" && lastActivated) {
          return true;
        } else if (activated === true) {
          return true;
        } else {
          // spectodaSound.stop();
          return false;
        }
      }
    });
  }

  function stop() {}

  async function queue(msg: KateMsg) {
    setMessages(msgs => [...msgs, msg]);
  }

  return (
    <KateContext.Provider
      value={{
        queue,
        say,
        sayRandom,
        stop,
        messages,
        activate: setActivated,
        activated: activated,
      }}
    >
      {children}
    </KateContext.Provider>
  );
}
