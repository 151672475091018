import { tangleDevice } from "lib/utils/communication";
import React, { FC, useEffect } from "react";

export default function Layout({ children }: React.PropsWithChildren<any>) {
  useEffect(() => {
    tangleDevice.requestWakeLock();

    // setInterval(() => {
    // },60000)
  });
  return (
    <>
      <div
        className="fixed -mb-4"
        onTouchStart={(e) => {
          // e.currentTarget.requestFullscreen();
        }}
      >
        {/* <button>Fullscreen</button> */}
        {children}
      </div>
    </>
  );
}
