import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export default function KatePopup() {
   const navigate = useNavigate();

   return (
      <div className="absolute  z-50  w-[100vw] h-[100vh] bg-[#ffffffb5]">
         <div className="flex bg-[#F4FBFD] rounded-[40px] m-8 overflow-hidden">
            <div className="w-[560px]">
               <img src="other/more.png" alt="" />
               <div className="mb-5"></div>
            </div>
            <div className="w-[450px] ">
               <motion.img
                  src="exit.svg"
                  whileTap={{ scale: 0.9 }}
                  className="ml-auto -mr-10 h-16 mt-6 "
                  onTouchStart={() => navigate("/")}
               />
               <h2 className="text-[36px] mt-16 font-extrabold ">
                  Více o Kate
               </h2>
               <p className="mt-6 text-xl">
                  S Kate vyřešíte vše od odeslání platby, problému s kartou,
                  sjednání schůzky na pobočce a mnohem více.
               </p>
               <p className="mt-6 text-xl">
                  <b className="font-bold">Chcete se dozvědět více?</b>{" "}
                  Virtuální asistentku najdete v aplikaci ČSOB Smart.
               </p>
               <motion.button
                  className="px-10 mt-6 py-4 bg-[#0099CC] rounded-lg text-white font-semibold"
                  whileTap={{ scale: 0.95 }}
                  onTouchStart={() => navigate("/")}
               >
                  Zpět do menu
               </motion.button>
               {/* {revealedCount === 16 && <Overlay resetGame={resetGame} />}
          <div className="grid grid-cols-4 gap-3 mt-8 mb-8 mr-8">
            {cards.map((card, index) => (
              <PexesoCard
                index={index}
                {...card}
                key={index}
                flipCard={flipCard}
              />
            ))}
          </div> */}
            </div>
         </div>
      </div>
   );
}
