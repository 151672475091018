import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useTimer } from "react-timer-hook";
import { useLocation, useNavigate } from "react-router-dom";
import { pageMotion } from "Routing";
import { createNanoEvents } from "lib/tangle-js/functions";
import { useAnalytics } from "analytics/AnalyticsContext";
import { tangleDevice } from "lib/utils/communication";

function newTimeout() {
  return new Date(new Date().getTime() + 120 * 1000);
}

export const nanoevents = createNanoEvents();

let leaveDelayTimeout: NodeJS.Timeout;

export default function LeaverBuster({ children }: React.PropsWithChildren<any>) {
  const location = useLocation();
  const [expiryTimestamp] = useState(() => newTimeout());
  const [isBlack, setIsBlack] = useState(false);

  const { endTrackingCurrent } = useAnalytics();

  useEffect(() => {
    nanoevents.on("reset_timer", () => {
      setIsBlack(false);
      clearTimeout(leaveDelayTimeout);
      restart(newTimeout());
    });
  }, []);

  // const [countdown,setCountDown] = useState();
  const { seconds, minutes, hours, days, isRunning, start, pause, resume, restart } = useTimer({ expiryTimestamp, onExpire: onExpire });

  const navigate = useNavigate();

  function onExpire() {
    if (location.pathname !== "/") {
      navigate("/");
    }
    endTrackingCurrent();
    leaveDelay();
  }

  function leaveDelay() {
    clearTimeout(leaveDelayTimeout);

    leaveDelayTimeout = setTimeout(() => {
      setIsBlack(true);
    }, 10 * 1000); // * 60
  }

  useEffect(() => {
    return tangleDevice.on("event", (event: any) => {
      // throttle, kateInteractionHanle function
      // iteractionThrottler(kateInteractionHandle)
      if ("_btn_" === event.label && event.value < 1) {
        setIsBlack(false);
        clearTimeout(leaveDelayTimeout);
      }
    });
  }, []);

  const remainingSeconds = seconds + minutes * 60;

  return (
    <motion.div onTouchStart={() => restart(newTimeout())} initial="initial" animate="animate" exit="exit" variants={pageMotion}>
      {children}
      {location.pathname !== "/" && remainingSeconds <= 50 && (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#0000005a] h-[100vh] w-[100vw] flex justify-center items-center" onTouchStart={e => e.stopPropagation()}>
          <div className="bg-white p-16 rounded-2xl">
            <h1 className="text-center font-extrabold text-[36px]">Jste tu ještě?</h1>
            <div className="text-2xl font-extrabold text-center mt-5"></div>
            <div className="flex flex-col justify-around gap-4 mt-5">
              <motion.button onTouchStart={() => restart(newTimeout())} whileTap={{ scale: 0.98 }} className="rounded-xl border text-white border-[#0099CC] transition bg-[#0099CC] hover:bg-[#0086B2] px-10 py-3 font-semibold text-lg">
                Pokračovat
              </motion.button>
              <motion.button
                onTouchStart={e => {
                  navigate("/");
                }}
                whileTap={{ scale: 0.96 }}
                className="rounded-xl border px-10 py-3 font-semibold text-lg border-[#0099CC] bg-[white] text-[#0099CC] transition hover:bg-[#CFECF4]"
              >
                Zpět do menu ({remainingSeconds})
              </motion.button>
            </div>
          </div>
        </div>
      )}
      {isBlack && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 3 }}
          className="fixed top-0 left-0 right-0 bottom-0 bg-[#000000ff] h-[100vh] w-[100vw] flex justify-center items-center z-[1000000]"
          onTouchStart={e => {
            e.stopPropagation();
            setIsBlack(false);
            clearTimeout(leaveDelayTimeout);
          }}
        ></motion.div>
      )}
    </motion.div>
  );
}
