import { atom, useAtom } from "jotai";
import { tangleDevice } from "lib/utils/communication";
import { useSpectodaSound } from "lib/utils/utils";
import React, { useEffect, useContext } from "react";
import { TangleConnection } from "TangleConnectionContext";
import CSOB from "../components/CSOB";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { KateContext } from "components/Kate";
import { useAnalytics } from "analytics/AnalyticsContext";

// create jotai atom which stores the current animation
export const activeAtom = atom("off");

// throtte function function
function throttle(fn: Function, limit: number) {
  let inThrottle: boolean;
  return function (event: any, say: Function, startUsageTracking: Function) {
    const args = arguments;
    if (!inThrottle) {
      fn(event, say, startUsageTracking);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
}

function kateInteractionHandle(event: any, say: Function, startUsageTracking: Function) {
  if ("_btn_" === event.label && event.value < 1) {
    say({
      mp3: "story/vitejte.mp3",
      msg: "Vítejte, já jsem Kate a chtěla bych vám ukázat co umím.",
    });
    startUsageTracking("movement");
  }
}
const iteractionThrottler = throttle(kateInteractionHandle, 10000);

// throttle kateInteraction function

export default function Home() {
  const navigate = useNavigate();
  const { activate, say } = useContext(KateContext);
  const { startTracking, finishTrackingCurrent } = useAnalytics();

  function startUsageTracking(type: string) {
    startTracking("usage", type);
  }

  useEffect(() => {
    let can = false;
    finishTrackingCurrent();

    setTimeout(() => {
      can = true;
    }, 10000);
    const clearEventHandler = tangleDevice.on("event", (event: any) => {
      // kateInteractionHandle()
      // throttle, kateInteractionHanle function
      // iteractionThrottler(kateInteractionHandle)
      if (can) {
        iteractionThrottler(event, say, startUsageTracking);
      }
    });
    return clearEventHandler;
  }, []);

  // const [active, setActive] = useAtom(activeAtom);
  // const [microphoneSensitivity, setMicrophoneSensitivity] =
  //   useAtom(micSensitivityAtom);
  // const { start, stop, spectodaSound } = useSpectodaSound();
  const { connectionStatus, connect } = useContext(TangleConnection);

  // pick random unique 8 numbers from 1-20
  // const randomNumbers = Array.from(
  //   { length: 8 },
  //   () => Math.floor(Math.random() * 20) + 1
  // ).filter((v, i, a) => a.indexOf(v) === i);

  useEffect(() => {
    activate(false);
    tangleDevice.emitEvent("home");
  }, []);

  const cards = [
    {
      title: "Student",
      name: "Robert",
      image: "home/robert.svg",
      route: "student",
    },
    {
      title: "Zubař",
      name: "David",
      image: "home/david.svg",
      route: "zubar",
    },
    {
      title: "Maminka",
      name: "Alena",
      image: "home/alena.svg",
      route: "maminka",
    },
    {
      title: "Zpěvačka",
      name: "Julie",
      image: "home/julie.svg",
      route: "zpevacka",
    },
  ];

  return (
    <div className="w-[100vw] h-[100vh] flex" style={{ background: "url(home/bg.svg) 1450px 950px" }} onClick={() => startUsageTracking("click")}>
      <div className="w-[800px] max-w-[800px] p-16">
        {/* <h1 className="text-6xl">Diky Matty!!!</h1> */}
        {/* {window.screen.height + " X " + window.screen.width}
        <br />
        {"DPI: " + window.devicePixelRatio} */}
        <h1 className="font-extrabold text-[55px] flex text-white">
          <motion.img
            whileTap={{ scale: 1.1 }}
            src="home/kate.svg"
            alt=""
            className="mr-8 w-16 "
            // @ts-ignore
            // onDoubleClick={() => location.reload(true)}
          />
          {/* <button onClick={() => (location.href = "https://3000-spectoda-csob-k8aybn1du1w.ws-eu81.gitpod.io/")}>DEBUG</button> */}
          Seznamte se s Kate
        </h1>
        <div className="mt-12 ">
          <h2 className="text-[32px] font-extrabold ">Vyberte si postavu</h2>
          <p className="font-semibold text-[20px] ">Zajímá vás více? Pojďte se mnou na výlet!</p>
        </div>
        <div className="mt-12">
          <div className="grid grid-cols-2 gap-10 max-w-lg">
            {cards.map((card, key) => (
              <ProfileCard key={key} {...card} onTouchStart={() => navigate("story/" + card.route)} />
            ))}
          </div>
        </div>
        <div className="mt-16">
          <motion.img
            whileTap={{ scale: 1.1 }}
            onTouchStart={async () => {
              navigate("/kate");
              // say({
              //   msg: "Já umím mluvit",
              //   mp3: "story/dvanact-tak-to-je-pohoda.mp3",
              // });
            }}
            src="home/quest.svg"
            className="h-6 "
            alt=""
          />
        </div>
      </div>
      <div className="flex flex-col w-[400px] items-end">
        {/* <button onTouchStart={() => }>Connect</button> */}

        <motion.img
          // onTouchStart={() => connect()}
          whileTap={{ rotate: "180deg", scale: 1.1 }}
          src="home/leon.png"
          alt=""
          className="w-[380px]"
        />

        <div className="relative mr-16 flex flex-col items-end">
          <h2 className="text-[36px] mt-24 font-extrabold text-right">Něco pro menší</h2>
          <p className="font-semibold text-[20px] text-right ">Zahrajte si pexeso!</p>

          <div className="relative mt-14">
            <img src="home/dinocard2.png" alt="" className="absolute -top-12 -left-20 w-[150px] z-0" />
            <img src="home/dinocard1.png" alt="" className="absolute top-8 -left-12 w-[120px] z-20" />

            <motion.button whileTap={{ scale: 0.96 }} onTouchStart={() => navigate("pexeso")} className="absolute rounded-2xl  text-white px-9 py-6 font-extrabold text-2xl z-10 bg-[#003366] transition hover:bg-[#002447]">
              Hrát pexeso
            </motion.button>

            <button className="opacity-0 rounded-2xl bg-[#003366] text-white px-9 py-6 font-extrabold text-2xl z-10">Hrát pexeso</button>
          </div>

          {/* <motion.img
            onTouchStart={() => navigate("pexeso")}
            className="w-96 max-w-none absolute -right-12"
            whileTap={{ scale: 0.96 }}
            src="home/play.svg"
            alt=""
          /> */}
        </div>
      </div>
    </div>
  );
}

function ProfileCard(card: any) {
  return (
    <motion.div whileTap={{ scale: 0.96 }} onTouchStart={card.onTouchStart}>
      <motion.div animate={{ background: "white" }} whileTap={{ background: "#CFECF4" }} className="flex  rounded-xl shadow-[0px_24px_24px_rgba(2, 77, 94, 0.1)] py-4 px-6 items-center h-24 transition ">
        <img src={card.image} className={card.name == "David" ? "scale-110" : "scale-75"} alt="" />
        <div className="ml-4 flex flex-col justify-center">
          <h3 className="text-[16px] mt-2 font-semibold">{card.title}</h3>
          <p className="text-[24px] font-extrabold -mt-2">{card.name}</p>
        </div>
      </motion.div>
    </motion.div>
  );
}
