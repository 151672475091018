import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

export function Navbar({ part, title }: { part: string; title: string }) {
  const navigate = useNavigate();

  return (
    <nav className="w-full flex items-center bg-white justify-between p-8 py-3">
      <div className="w-64 ">
        <motion.div
          onTouchStart={() => navigate("/")}
          animate={{ background: "#F4FBFD" }}
          whileTap={{ scale: 0.9, background: "#CFECF4" }}
          className="w-16 h-12 rounded-xl flex justify-center items-center transition  p-4"
        >
          <img src="back.svg"></img>
        </motion.div>
      </div>

      <div className="title text-xl font-extrabold flex items-center">
        {part} <div className="h-10 w-[1px] mx-3 bg-[#C2DDE9]"></div>{" "}
        <span className="font-medium">{title}</span>
      </div>
      <div className="next w-64 flex justify-end">
        <img src="CSOB.png" className="h-10" />
      </div>
    </nav>
  );
}
