// import esPkg from 'essentia.js';
import { detectSpectodaConnect } from "lib/tangle-js/functions";
import { setLoggingLevel } from "../tangle-js/Logging";
import { TangleDevice } from "../tangle-js/TangleDevice";
import { TangleMsgBox } from "../webcomponents/dialog-component";

const primaryColor = "#ff8c5c";

const tangleDevice = new TangleDevice("default", 1000, true);
tangleDevice.setLanguage("cs");

// console.log("AutoConnect")
// Matty key
// console.log("AutoConnect")
// csobkate key
tangleDevice.assignOwnerSignature(localStorage.getItem("ownerSignature") || "3048f234a76618be0772365deaac301f");
tangleDevice.assignOwnerKey(localStorage.getItem("ownerKey") || "bfd39c89ccc2869f240508e9a0609420");
// // Lukas
// tangleDevice.assignOwnerSignature("65adda4326914576405c9e3a62f4904d");
// tangleDevice.assignOwnerKey("bfd39c89ccc2869f240508e9a0609420");
//@ts-ignore
window.tangleDevice = tangleDevice;
setLoggingLevel(0);
process.env.NODE_ENV === "development" && setLoggingLevel(4);
// process.env.NODE_ENV === "production" && tangleDevice.assignConnector("webbluetooth");
let url = new URL(location.href);
let params = new URLSearchParams(url.search);

if (params.get("demo")) {
  setTimeout(() => {
    tangleDevice.assignConnector("dummy");
  }, 300);
}

if (detectSpectodaConnect()) {
  tangleDevice.connect();
}

export { tangleDevice };

// const essentia = new esPkg.Essentia(esPkg.EssentiaWASM);
