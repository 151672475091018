import { AnalyticsData } from "./AnalyticsContext";

const deviceUUID = "CSOB_TABLET_KATE_VACLAVAK";

export function addRecord(record: any, shouldTriggerPost = true) {
  // save to storage
  let data = JSON.parse(localStorage.getItem("analytics") || "[]");
  data.push(record);
  localStorage.setItem("analytics", JSON.stringify(data));

  if (shouldTriggerPost) {
    getAndPostRecords();
  }
}

export function getAndPostRecords() {
  let data = JSON.parse(localStorage.getItem("analytics") || "[]");

  if (data) {
    // post to server
    fetch("https://dev.studio.spectoda.com/api/analytics-apps", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-device-uuid": deviceUUID,
      },
      body: JSON.stringify({ data: data as AnalyticsData[] }),
    })
      .then(() => {
        // clear storage
        localStorage.setItem("analytics", "[]");
      })
      .catch(err => {
        // addRecord(
        //   {
        //     type: "internet-connectivity",
        //     value: err,
        //   },
        //   false,
        // );
      });
  }
}
getAndPostRecords();
